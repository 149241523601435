import { toastController } from "@ionic/vue";

const createToast = async (message, duration = 4000) => {
	let toast = await toastController.create({
		message,
		duration,
	});

	await toast.present();
};
export { createToast };
