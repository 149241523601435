<template>
	<base-layout v-on:refresh="refresh($event)">
		<div
			style="height: 85vh; overflow: auto; padding-bottom: 10px"
			class="scrollableDiv"
		>
			<ion-list lines="inset">
				<h1>Garmin Backfill</h1>
				<ion-item>
					<form @submit="backfill">
						<fieldset>
							<legend>Type of Operation</legend>
							<div>
								<input
									type="radio"
									id="all"
									name="type"
									value="all"
									v-model="mode"
									required
								/>
								<label for="all">&nbsp;&nbsp;All Users</label>
							</div>
							<div>
								<input
									type="radio"
									id="single"
									name="type"
									value="single"
									v-model="mode"
									required
								/>
								<label for="single">&nbsp;&nbsp;Single User (fill UID)</label>
							</div>
						</fieldset>
						<br />
						<input id="uid" type="string" name="uid" v-model="uid" />
						<label for="uid">&nbsp;&nbsp;User's UID</label>
						<br />
						<br />
						<input
							id="start"
							type="datetime-local"
							name="start"
							required
							v-model="start"
						/>
						<label for="start">&nbsp;&nbsp;Start Time</label>
						<br />
						<br />
						<input
							id="end"
							type="datetime-local"
							name="end"
							required
							v-model="end"
						/>
						<label for="end">&nbsp;&nbsp;End Time</label>
						<br />
						<br />
						<input type="submit" value="Backfill" />
					</form>
				</ion-item>
				<h2>Response</h2>

				<ion-item>
					<textarea :value="response" style="width: 100%" rows="10" />
				</ion-item>
			</ion-list>
		</div>
	</base-layout>
</template>

<script>
	import moment from "moment";

	import { IonList, IonItem } from "@ionic/vue";
	import { add, settings } from "ionicons/icons";

	import { createToast } from "../../services/toast";
	import { garminBackfill } from "../../services/api";

	export default {
		// eslint-disable-next-line vue/multi-word-component-names
		name: "Backfill",

		components: {
			IonList,
			IonItem,
		},
		data() {
			return {
				response: "",
				start: "",
				end: "",
				uid: "",
				mode: "",
			};
		},
		computed: {},
		methods: {
			backfill(e) {
				this.response = "";
				e.preventDefault();
				//console.log(e);
				const { start, end, uid, mode } = this;
				console.log(start, end, uid, mode);

				const startUnix = moment(start).unix();
				const endUnix = moment(end).unix();

				if (endUnix > startUnix) {
					console.log(startUnix, endUnix);
					switch (mode) {
						case "all": {
							garminBackfill(startUnix, endUnix, mode).then(
								(res) => (this.response = res)
							);
							break;
						}
						case "single": {
							if (!uid) {
								createToast("Please input a uid");
							} else {
								garminBackfill(startUnix, endUnix, mode, uid).then(
									(res) => (this.response = res)
								);
							}
							break;
						}
					}
				} else {
					createToast("Start time has to be before end time");
				}
			},
		},
		setup() {
			return {
				add,
				settings,
			};
		},

		mounted() {},
	};

	/*
	  <scores-widget :cirlce1="circle1" :circle2="circle2" :circle3="circle3"/>
	       */
</script>

<style scoped>
	h1 {
		color: white;
	}
	h2 {
		color: white;
	}
	textarea {
		color: white;
	}
	form {
		color: white;
	}
	input {
		width: 400px;
	}
	fieldset input {
		width: fit-content;
	}
	ion-item {
		border: 1px solid #666666;
		border-radius: 10px;
		padding: 10px 0;
	}
</style>
